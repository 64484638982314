.pageWrapper {
  max-width: 500px;
  height: auto;
  margin: 30px auto;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 120px - 280px);

  .errorWrapper {
   text-align: center;
   text-transform: uppercase;
   font-size: 22px;
   color: #f3337e;
   margin: 20px 0;
  }
}