@import '../../styles/components/buttons';
@import '../../styles/components/form';
@import '../../styles/components/confirm-alert';

.pageWrapper {
  max-width: 800px;
  height: auto;
  margin: 30px auto;
  display: flex;
  flex-direction: column;

  .pageHeader {
    .title {
      font-size: 28px;
      margin: 10px 0 35px;
      color: #f3337e;
      text-transform: uppercase;
    }
  }

  .actions {
    width: 100%;
    margin-top: 35px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;

    .button {
      margin: 20px auto 0;
    }

    .formControl {
      margin-bottom: 15px;
      display: flex;
      align-items: baseline;

      input {
        margin-right: 10px;
      }
    }
  }
}

.contentWrapper {
  text-align: left;
  word-break: break-word;

  .contentData {
    .contentTitle {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 15px;
    }

    .formDescription {
      margin-bottom: 20px;
    }

    p {
      margin-bottom: 10px;

      i {
        margin-bottom: 15px;
        display: block;
      }
    }

    ul {
      margin-bottom: 15px;

      li {
        margin-left: 40px;
        margin-top: 10px;

        ul {
          li {
            margin-left: 40px
          }
        }
      }

      ul {
        li {
          margin-left: 60px;
        }
      }
    }
  }

  .userName {
    margin-bottom: 20px;
    font-weight: bold;
    text-transform: uppercase;

    .changeUser {
      margin-left: 5px;
      text-transform: lowercase;
      text-decoration: underline;
    }
  }

  .pagination {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    font-size: 14px;

    .page {
      display: block;
      min-width: 40px;
      height: 20px;
      margin-right: 0;
    }

    .linkText {
      &.disabled {
        color: #999;
        pointer-events: none;
      }
    }

    .currentPage {
      font-weight: bold;
      height: 20px;
    }

    span {
      margin-right: 10px;
    }
  }
}

.vaccine {
  flex-direction: column;
}