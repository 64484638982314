@import '../../styles/components/buttons';
@import '../../styles/components/form';

.pageWrapper {
  max-width: 500px;
  height: auto;
  margin: 30px auto;
  display: flex;
  flex-direction: column;

  .pageHeader {
    .title {
      font-size: 28px;
      margin: 10px 0 35px;
      color: #f3337e;
      text-transform: uppercase;
      text-align: center;
    }
  }

  .pageFooter {
    margin-top: 15px;
    margin-bottom: 15px;
    font-size: 15px;
    border-left: 5px solid #f32555;
    background-color: rgba(253, 226, 237, 0.39);
    line-height: 1.4;
    padding: 5px 10px;
    text-align: left;
  }

  .warningMessage {
    line-height: 1.4;
    padding: 5px 10px;
    text-align: left;
    border-left: 5px solid #ffa500;
    background-color: rgba(255, 165, 0, 0.19);
    margin-top: 0;
    margin-bottom: 20px;
  }

  .userSession {
    display: block;
    margin-top: 15px;
    font-size: 12px;
    position: absolute;
    bottom: -24px;
    opacity: 0.8;
  }

  .actions {
    margin-top: 20px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;

    .formControl {
      margin-bottom: 15px;
      display: flex;
      align-items: center;

      input {
        margin-right: 10px;
      }
    }
  }
}

.contentWrapper {
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 32px;

  .contentIcon {
    img {
      max-width: 144px;
      margin-bottom: 20px;
    }
  }

  .successMessage {
    font-size: 26px;
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
    margin: 10px 0 15px;
  }

  .contentFooter {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    font-weight: bold;

    span {
      margin-bottom: 5px;
      text-transform: uppercase;
    }

    .dateSession {
      font-size: 22px;
    }
  }
}