.button {
  padding: 12px 22px;
  width: 180px;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #ececec;
  transition: all ease-in-out 0.3s;

  &:disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &:hover {
    background-color: #ececec;
  }

  &.default {
    background-color: #fff;
    color: #000;
    border: 1px solid #000;
  }

  &.primary {
    background-color: #000;
    color: #e5e500;
    border: 1px solid #000;

    &:hover {
      background-color: #fff;
      color: #000;
      border: 1px solid #000;
    }
  }
}

.linkText {
  color: #953B8C;
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
}