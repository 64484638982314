.appHeader {
  width: 100%;
  height: 60px;
  padding: 10px 20px;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(351deg, rgba(171,57,133,1) 0%, rgba(244,37,85,1) 27%);
  display: flex;
  align-items: center;

  .tasteLogo {
    width: 70px;
    height: auto;

    img {
      width: 100%;
      height: auto;
    }
  }
}