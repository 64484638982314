@import '../../styles/components/buttons';
@import '../../styles/components/form';

.pageWrapper {
  max-width: 400px;
  height: auto;
  margin: 30px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .pageHeader {
    .title {
      font-size: 28px;
      margin: 10px 0 20px;
      color: #f3337e;
      text-transform: uppercase;
    }

    .headerDescription {
      margin-bottom: 24px;
    }
  }

  .formWrapper {
    width: 100%;
  }

  .actions {
    text-align: center;
    margin-top: 30px;
  }
}