@import '../../styles/components/buttons';

.appFooter {
  width: 100%;
  min-height: 130px;
  padding: 60px 20px;
  background: linear-gradient(156deg, rgba(244,54,130,1) 23%, rgba(244,37,85,1) 37%);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .tasteLogo {
    width: 120px;
    height: auto;
    
    img {
      width: 100%;
      height: auto;
    }
  }

  .appRights {
    color: #fff;
    font-weight: bold;
    margin-top: 20px;
    font-size: 12px;
    text-align: center;
  }
}

.footerNavs {
  width: 80%;
  color: #fff;
  margin-bottom: 30px;

  p {
    margin-bottom: 10px;

    i {
      margin-bottom: 15px;
      display: block;
    }
  }


  ul {
    list-style: none;
    margin-bottom: 15px;

    li {
      margin-left: 20px;
      margin-top: 10px;

      ul {
        li {
          margin-left: 20px
        }
      }
    }
  }

  .footerNav {
    &__title {
      text-transform: uppercase;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
      padding-bottom: 10px;
      border-bottom: 2px solid transparent;
      cursor: pointer;
      font-weight: bold;

      .chevronIcon {
        &:before {
          position: relative;
          content: "";
          display: inline-block;
          width: 8px;
          height: 8px;
          border-right: 2px solid #fff;
          border-top: 2px solid #fff;
          transform: rotate(136deg);
          margin-right: 0.5em;
        }
      }
    }

    &__content {
      overflow:hidden;
      transition:transform 0.3s ease-out;
      height:auto;
      transform:scaleY(0);
      transform-origin:top;
    }

    &.expanded {
      .footerNav__title {
        border-bottom: 2px solid #fff;
      }

      .footerNav__content {
        transform:scaleY(1); // *squish*

        button {
          margin-top: 20px;
          width: 100%;
          margin-bottom: 20px;
        }
      }
    }
  }
}

.poweredBy {
  display: flex;
  align-items: center;
  color: #fff;
  margin-top: 25px;
  font-weight: bold;

  img {
    width: 100px;
    margin-left: 10px;
  }
}