.react-confirm-alert-overlay {
  .react-confirm-alert {
    border-radius: 0;

    .react-confirm-alert-body {
      border-radius: 0;
      text-align: center;
      min-width: 300px;
      width: 90vw;
      max-width: 400px;

      h1 {
        text-transform: uppercase;
        color: #f3337e;
        font-size: 24px;
        margin-bottom: 10px;
      }

      .react-confirm-alert-button-group {
        justify-content: center;

        .button {
          width: auto;
          border-radius: 0;
          margin: 0;

          & + button {
            margin-left: 10px;
          }
        }
      }
    }
  }
}