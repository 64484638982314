.formGroup {
  .formControl {
    .control__indicator {
      top: -2px;
    }
  }
}

.formControl {
  margin-bottom: 30px;
  position: relative;

  .control {
    position: relative;
    display: block;
    margin-bottom: 15px;
    padding-left: 30px;
    cursor: pointer;

    input {
      position: absolute;
      z-index: -1;
      opacity: 0;
    }
  }

  .control__indicator {
    position: absolute;
    top: 2px;
    left: 0;
    width: 20px;
    height: 20px;
    background: #fff;
    border: 2px solid #19191E;
  }

  .control:hover input ~ .control__indicator,
  .control input:focus ~ .control__indicator {
    border: 2px solid #19191E;
    background: #fff;
  }

  .control input:checked ~ .control__indicator {
    border: 2px solid #19191E;
    background: #fff;
  }

  .control:hover input:not([disabled]):checked ~ .control__indicator,
  .control input:checked:focus ~ .control__indicator {
    border: 2px solid #19191E;
    background: #fff;
  }

  .control input:disabled ~ .control__indicator {
    pointer-events: none;
    opacity: .6;
    background: #e6e6e6;
  }

  .control__indicator:after {
    position: absolute;
    display: none;
    content: '';
  }

  .control input:checked ~ .control__indicator:after {
    display: block;
  }

  .controlCheckbox .control__indicator:after {
    top: 0px;
    left: 5px;
    width: 5px;
    height: 10px;
    transform: rotate(45deg);
    border: solid #953B8C;
    border-width: 0 2px 2px 0;
  }

  .controlCheckbox input:disabled ~ .control__indicator:after {
    border-color: #7b7b7b;
  }

  label {
    margin-bottom: 8px;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: bold;
    display: block;

    .asterisk {
      color: #f3337e;
      margin-left: 2px;
    }
  }

  .description {
    display: block;
    font-size: 12px;
    margin-bottom: 12px;
    opacity: 0.8;
  }

  &.notValid {
    input[type=text],
    input[type=number] {
      border: 2px solid #f3337e;
    }

    label {
      color: #f3337e;
    }
  }

  input[type=text],
  input[type=number]{
    width: 100%;
    box-sizing: border-box;
    //border-radius: 10px;
    padding: 14px;
    border: 2px solid #ccc;
    transition: all ease-in-out 0.3s;
    color: #000;
    font-size: 14px;
    font-weight: 500;

    &:focus {
      outline: none;
      border: 2px solid #000;
    }
  }
}

.notificationHolder {
  .notification {
    margin: 14px 0 20px;
    font-size: 14px;
    font-weight: 500;

    &.success {
      color: #00c22d;
    }

    &.error {
      color: #f3337e;
    }
  }
}

.inputError{
  color: #f3337e;
  font-size: 12px;
  position: absolute;
  left: 2px;
  bottom: -18px;
  transition: all ease-in-out 0.3s;
}

.checkControls {
  .formControl {
    cursor: pointer;

    input {
      cursor: pointer;
    }

    label {
      cursor: pointer;
    }
  }
}

.formGroup {
  margin-bottom: 20px;

  .title {
    font-weight: bold;
    display: block;
    margin-bottom: 10px;

    .asterisk {
      color: #f3337e;
      margin-left: 2px;
    }
  }

  .description {
    font-size: 12px;
    display: block;
    margin-bottom: 10px;
  }
}

.radioGroup {
  display: flex;
  align-items: center;
  margin-top: 15px;

  &.vaccine {
    flex-direction: column;
    align-items: flex-start;

    .radioControl {

      &+.radioControl {
        margin-left: 0;
        margin-top: 10px;
      }
    }
  }

  .radioControl {
    display: flex;
    align-items: center;

    &+.radioControl {
      margin-left: 10px;
    }

    input {
      cursor: pointer;
    }

    label {
      margin: 0 0 0 5px;
      cursor: pointer;
    }
  }
}

[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label
{
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #19191E;
}
[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 2px solid #19191E;
  border-radius: 100%;
  background: #fff;
}
[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
  content: '';
  width: 10px;
  height: 10px;
  background: #953B8C;
  position: absolute;
  top: 6px;
  left: 6px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
[type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
[type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}